<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-table
          ref="paymentsTable"
          class="position-relative text-nowrap"
          :items="payments"
          responsive
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
      >

        <template #cell(order)="data">
					<span>
						{{ data.item.order }}
					</span>
        </template>

        <template #cell(title)="data">
					<span>
						{{ data.item.title }}
					</span>
        </template>

        <template #cell(days)="data">
					<span>
						{{ data.item.days }}
					</span>
        </template>

        <template #cell(revisions)="data">
					<span>
						{{ data.item.revisions }}
					</span>
        </template>

        <template #cell(price)="data">
					<span>
						${{ data.item.price }}
					</span>
        </template>

        <template #cell(percentage)="data">
					<span>
						{{ data.item.percentage }}%
					</span>
        </template>

        <template #cell(status)="data">
					<span>
						{{ data.item.status }}
					</span>
        </template>

        <template #cell(paymentDate)="data">
					<span v-if="data.item.paymentDate">
						{{ getFormattedDate(data.item.paymentDate) }}
					</span>
          <span v-else>-</span>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalPayments"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BLink,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup, BDropdownForm, BFormSelect,
} from 'bootstrap-vue';
import moment from 'moment';

export default {
  components: {
    BFormSelect, BDropdownForm, BFormGroup,
    BCard,
    BRow,
    BCol,
    BLink,
    BAvatar,
    BBadge,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
  },

  props: ['projectId'],

  data() {
    return {
      currentPage: 1,
      perPage: 100,
      perPageOptions: [10, 25, 50, 100],
      tableColumns: [
        {
          key: 'order',
          label: 'Number',
          sortable: true
        },
        {
          key: 'title',
          label: 'Milestone Title',
          sortable: true
        },
        {
          key: 'days',
          label: 'Delivery Days',
          sortable: true
        },
        {
          key: 'revisions',
          label: 'Number of revisions',
          sortable: true
        },
        {
          key: 'price',
          label: 'Price',
          sortable: true
        },
        {
          key: 'percentage',
          label: '%',
          sortable: true
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'paymentDate',
          label: 'Payment Date',
          sortable: true
        }
      ],
      sortBy: 'order',
      sortDesc: false,
      payments: [],
      totalPayments: 0,
    };
  },

  computed: {
    dataMeta() {
      return {
        from: this.perPage * (this.currentPage - 1) + (this.payments.length ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + this.payments.length,
        of: this.totalPayments,
      }
    },

    queryParams() {
      return {
        sortBy: this.sortBy,
        sortOrder: this.sortDesc ? 1 : 0,
        skip: (this.currentPage - 1) * this.perPage,
        take: this.perPage,
      }
    }
  },

  watch: {
    queryParams: {
      handler() {
        this.fetchPaymentDetails();
      },
      immediate: true
    },
  },

  methods: {
    async fetchPaymentDetails() {
      const params = this.queryParams;

      const response = await this.$http.get(`projects/${this.projectId}/Milestones`, {
        params
      });

      this.payments = response.data.results ?? [];
      this.totalPayments = response.data.count;
    },

    getFormattedDate(date) {
      return moment.utc(date).format('yyyy-MM-DD');
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>

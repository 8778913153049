<template>
  <div class="modal fade" ref="modal" @keydown.esc="hide" @keydown.right="$refs.carousel.next()" @keydown.left="$refs.carousel.prev()" tabindex="0">
    <feather-icon @click="hide" icon="XIcon" role="button" size="25" class="text-white position-absolute" style="right: 50px; top: 20px"/>

    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content position-relative border-0 bg-transparent justify-content-center align-items-center">
        <b-carousel
            id="imageGallery"
            ref="carousel"
            v-model="currentIndex"
            :interval="0"
        >
          <div v-for="(img, index) in imgs" :key="index">
            <b-carousel-slide id="carousel-slide">
              <template #img>
                <img
                    class="d-block img-fluid w-100"
                    :src="img"
                    :alt="img"
                    style="object-fit: cover;"
                >
              </template>
            </b-carousel-slide>
          </div>
        </b-carousel>
      </div>
    </div>

    <button class="carousel-control-prev" type="button" @click.stop="" data-bs-target="#imageGallery" data-bs-slide="prev" @click="$refs.carousel.prev()">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    </button>

    <button class="carousel-control-next" type="button" @click.stop="" data-bs-target="#imageGallery" data-bs-slide="next" @click="$refs.carousel.next()">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
    </button>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide } from 'bootstrap-vue'
import { Modal } from 'bootstrap';

export default {
  components: {
    BCarousel,
    BCarouselSlide
  },

  data() {
    return {
      modal: null,
      currentIndex: null,
    };
  },

  props: {
    imgs: {
      type: Array,
      default: Array
    },
    // index of active image
    activeImage: {
      type: Number,
      required: true,
    },
  },

  watch: {
    activeImage: {
      handler(value) {
        this.currentIndex = value;
      }
    }
  },

  methods: {
    show() {
      this.currentIndex = this.activeImage;
      this.modal.show();
    },

    hide() {
      this.modal.hide();
    }
  },

  mounted() {
    const modalRef = this.$refs.modal;

    this.modal = new Modal(modalRef, {
      backdrop: true,
      keyboard: false
    });
  },

  unmounted() {
    this.hide();
  }
}
</script>

<style scoped lang="scss">
.modal {
  background: rgba(0, 0, 0, 0.80);
  height: 100vh !important;

  .btn-close {
    top: 35px;
    z-index: 999999;
    box-shadow: none;
  }

  #imageGallery {
    width: 60%;

    #carousel-slide {
      height: 600px;

      img {
        height: 600px;
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    transition: transform 4s ease-in-out;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background: grey;
  }

  .carousel-control-prev {
    margin-left: 40px;
  }

  .carousel-control-next {
    margin-right: 40px;
  }

  @media (max-width: 1199.98px) {
    #imageGallery {
      width: 90% !important;

      #carousel-slide {
        height: 300px;

        img {
          height: 300px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    #imageGallery {
      width: 100% !important;
    }
  }
}
</style>

<template>
    <div>
        <b-card no-body class="mb-0">
            <div style="width: 100%; height: 1000px;">
                <div style="width: 100%; height: 1000px;" id="chart">
                    <apexchart width="100%" type="rangeBar" :options="options" :series="series">
                    </apexchart>
                </div>
            </div>
        </b-card>
    </div>
</template>


<script>

import {
    BCard, BCardHeader, BCardBody, BCardText, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem, BInputGroup, fontAwesomeIcon,
    BDropdownForm, BFormDatepicker, BFormGroup, BTabs, BTab, BCollapse, VBToggle
} from 'bootstrap-vue';
// import ApexCharts from 'apexcharts'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment';
import {maxValue} from "@core/utils/validations/validations";
import { timeline } from 'echarts/lib/theme/dark';


export default {
    components: {
        BCard,
        BRow,
        BCol,
        BLink,
        BAvatar,
        BBadge,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        fontAwesomeIcon,
        BDropdownForm,
        BFormDatepicker,
        BFormGroup,
        BTabs,
        BTab,
        BCardHeader,
        BCardBody,
        BCollapse,
        BCardText,
        apexchart: VueApexCharts
    }, directives: {
        'b-toggle': VBToggle
    },
    props: ['projectId'],
    data() {
        return {
            timeline1: [] ,
            series: [],
            options: {
                plotOptions: {
                    bar: {
                      horizontal: true,
                      distributed: true,
                      dataLabels: {
                          hideOverflowingLabels: false
                      }
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        var label = opts.w.globals.labels[opts.dataPointIndex]

                        var a = moment(val[0])
                        var b = moment(val[1])
                        var diff = b.diff(a, 'days')
                        return label + ': ' + diff + (diff > 1 ? ' days' : ' day')
                    },
                    style: {
                        colors: ['#f3f4f5', '#fff']
                    }
                },
                xaxis: {
                  type: 'datetime'
                },
                yaxis: {
                  show: true,
        //           labels: {
        //     formatter: function (value, { seriesIndex, dataPointIndex, w }) {
        //         // Assuming 'this' refers to the Vue component
        //         if (this.timeline1[dataPointIndex]) {
        //             return this.timeline1[dataPointIndex].status;
        //         }
        //         return '';
        //     }
        // }
                },
                grid: {
                    row: {
                        colors: ['#f3f4f5', '#fff'],
                        opacity: 1
                    }
                }
            },
            colors: {
                Draft: 'gray',
                Pending: '#9B59B6',
                SearchingForDesigners: '#3498DB',
                DesignerSelected: '#06C',
                Completed: '#27AE60',
                NotAvailable: '#E74C3C'
            }
        }
    },
    created() {
        this.fetchTimeline()
    },
    mounted() {

        // var chart = new ApexCharts(document.querySelector("#chart"), this.options);

        // chart.render();
    },
    methods: {
      async fetchTimeline() {
        const response = await this.$http.get(`Projects/${this.projectId}/timeline`);
       
this.timeline1 = response.data;
        let timeline = response.data
        console.log(this.timeline1 );
       timeline.sort(function (a, b) {
            return new Date(a.createdOn) - new Date(b.createdOn);
        });

        this.series = [{ data: [] }]
        timeline.forEach((item, index) => {
          this.series[0].data.push({
              x: item.name,
              y: [
                  new Date(item.createdOn).getTime(),
                  timeline[index + 1] ? this.setBarWidth(item, timeline[index + 1]): this.setBarWidth(item)
              ],
              fillColor: this.colors[item.status]
          })
        });
      },

      setBarWidth(currentItem, nextItem) {
        let currentItemHours = new Date(currentItem.createdOn).getHours();

        if (nextItem) {
          let nextItemHours = new Date(nextItem.createdOn).getHours();

          if (nextItemHours - currentItemHours < 10) {
            return new Date(nextItem.createdOn).setHours(new Date(nextItem.createdOn).getHours() + 10);
          }

          return new Date(nextItem.createdOn).getTime();
        } else {
          return new Date(currentItem.createdOn).setHours(new Date(currentItem.createdOn).getHours() + 45);
        }
      }
    }
}
</script>

<style lang="scss" scoped></style>
<template>
  <div>
    <!-- Image Gallery / Project Images	-->
    <ImageGallery ref="projectImages"
                  :imgs="projectImages"
                  :activeImage="projectImageIndex"/>

    <!-- Image Gallery / Inspiration Images	-->
    <ImageGallery ref="inspirationImages"
                  :imgs="inspirationalImages"
                  :activeImage="inspirationImageIndex"/>

    <b-card no-body class="mb-0">

      <div class="d-flex flex-row justify-content-between w-100">

        <div class="d-flex flex-column w-75 mr-1">

          <div id="general-details-container"
            class="d-flex flex-column justify-content-between mb-1 border border-secondary rounded p-2">
            <div class="mb-2">
              <span class="titles"> general Details </span>
            </div>
            <div class="d-flex flex-row flex-wrap">

              <template v-for="key in Object.keys(generalDetails)">

                <div v-if="typeof generalDetails[key] === 'object' && generalDetails[key]"
                  class="d-flex flex-column justify-content-start m-1 w-25">
                  <div class="mb-1">
                    <span class="subtitles"> {{ formatTitleFromCamelCase(key) }} </span>
                  </div>
                  <div>
                    <span class="subtitles-value"> {{ generalDetails[key].text }} </span>
                  </div>
                </div>

                <div v-else-if="generalDetails[key]" class="d-flex flex-column justify-content-start m-1 w-25">
                  <div class="mb-1">
                    <span class="subtitles"> {{ formatTitleFromCamelCase(key) }} </span>
                  </div>
                  <div>
                    <span class="subtitles-value"> {{ generalDetails[key] }} </span>
                  </div>
                </div>

              </template>


            </div>
          </div>

          <div id="project-images-container"
            class="d-flex flex-column justify-content-between mb-1 border border-secondary rounded p-2">
            <div class="d-flex flex-row justify-content-between mb-2">
              <div>
                <span class="titles font-weight-bold"> Project Images </span>
              </div>
            </div>
            <div class="d-flex flex-row flex-wrap">
              <div v-for="(img, index) in projectImages" :key="index" class="w-25 m-1">
                <img class="d-block img-fluid" role="button" @click="showProjectImages(index)" :src="img" alt="image slot">
              </div>
            </div>
          </div>

          <div id="inspiration-images-container"
            class="d-flex flex-column justify-content-between mb-1 border border-secondary rounded p-2">
            <div class="d-flex flex-row justify-content-between mb-2">
              <div>
                <span class="titles font-weight-bold"> Inspiration Images </span>
              </div>
            </div>
            <div class="d-flex flex-row flex-wrap">
              <div v-for="(img, index) in inspirationalImages" :key="index" class="w-25 m-1">
                <img class="d-block img-fluid" role="button" @click="showInspirationImages(index)" :src="img" alt="image slot">
              </div>
            </div>
          </div>

          <div id="notes-container"
            class="d-flex flex-column justify-content-between border border-secondary rounded p-2">
            <div class="mb-2">
              <span class="titles font-weight-bold"> Notes </span>
            </div>
            <div class="d-flex flex-row flex wrap justify-content-between">
              <span class="subtitles-value"> {{ projectDetails.notes }} </span>
            </div>
          </div>

        </div>

        <div class="d-flex flex-column w-25">
          <div id="budget-container"
            class="d-flex flex-column justify-content-around w-100 border border-secondary rounded p-2">
            <div class="mb-2">
              <span class="titles"> Budget </span>
            </div>
            <div class="d-flex flex-row w-75 justify-content-between">
              <div>
                <span class="subtitles"> Min </span>
                <span class="subtitles-value text-danger"> ${{ projectDetails.budgetMin }} </span>

              </div>
              <div>
                <span class="subtitles"> Max </span>
                <span class="subtitles-value text-danger"> ${{ projectDetails.budgetMax }} </span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </b-card>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem, BInputGroup, fontAwesomeIcon,
  BDropdownForm, BFormDatepicker, BFormGroup, BTabs, BTab
} from 'bootstrap-vue';
import ImageGallery from "@/components/ImageGallery.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BAvatar,
    BBadge,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    fontAwesomeIcon,
    BDropdownForm,
    BFormDatepicker,
    BFormGroup,
    BTabs,
    BTab,
    ImageGallery
  },

  props: ['projectDetails', 'generalDetails'],

  data() {
    return {
      projectImageIndex: 0,
      inspirationImageIndex: 0
    };
  },

  computed: {
    projectImages() {
      const images = this.projectDetails && this.projectDetails.files && this.projectDetails.files.filter(file => file.type === "ProjectImage").map(f => f.url)

      return images
    },
    inspirationalImages() {
      const images = this.projectDetails && this.projectDetails.files &&this.projectDetails.files.filter(file => file.type === "InspirationImage").map(f => f.url)

      return images
    }
  },

  methods: {
    formatTitleFromCamelCase(str) {
      return str.replace(/([a-z0-9])([A-Z])/g, '$1 $2').split(' ').map(word => word.charAt(0).toUpperCase() +
        word.slice(1)).join(' ')
    },
    showProjectImages(index) {
      this.projectImageIndex = index;
      this.$refs.projectImages.show();
    },
    showInspirationImages(index) {
      this.inspirationImageIndex = index;
      this.$refs.inspirationImages.show();
    }
  }
}
</script>

<style lang="scss" scoped>
.titles {
  font-weight: 600;
  font-size: 18px;
  // line-height: 27px;
}

.subtitles {
  font-weight: 400;
  font-size: 14px;
  // line-height: 19.5px;
  color: #9C9C9C;
}

.subtitles-value {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.5px;
}
</style>
<template>
    <!-- <div>
        <b-card no-body class="mb-0"> -->
    <div class="accordion" role="tablist">

        <div v-for="(submission, index) in submissions" :key="index">
            <b-card no-body class="mb-1 p-1 border">
                <b-card-header header-tag="header" class="p-0 header" role="tab">
                    <h2> {{ submission.name }}</h2>

                    <!-- <b-button block v-b-toggle="'accordion-'+1">
                        <div class="d-flex flex-row justify-content-end">
                            <div class="d-flex flex-row w-50 rounded block justify-content-between">
                                <div>
                                    Submission
                                </div>
                                <div class="d-flex flex-content justify-content-between w-25">
                                    <div>
                                        4/29/2024 
                                    </div>
                                    <div>
                                        12:08:45 AM
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-button> -->
                </b-card-header>
                <div v-for="(submissionsAndRevisions, index) in submission.submissionsAndRevisions" :key="index">

                <b-card-body v-if="submissionsAndRevisions.type =='Submission' " class="d-flex flex-column justify-content-between p-1">
                    <b-button block v-b-toggle="'accordion-'+1">
                    <div class="d-flex flex-row w-40  justify-content-between">
                                <div>
                                    {{ submissionsAndRevisions.type }}
                                </div>
                                <div class="d-flex flex-content justify-content-between w-25">
                                    <div>
                                        {{ submissionsAndRevisions.date }}
                                    </div>
                                    <div>
                                        {{ submissionsAndRevisions.time }}
                                    </div>
                                </div>
                            </div>
                            </b-button>
                    <div class="d-flex flex-column justify-content-between w-100 h-100 p-1  mb-1">
                        <div>
                            <b-card-text class="titles mb-1"> {{ submissionsAndRevisions.title }} </b-card-text>
                        </div>
                        <div>
                            <b-card-text> {{ submissionsAndRevisions.description }}</b-card-text>
                        </div>
                    </div>

                    <div class="d-flex flex-column justify-content-between w-100 h-100 p-1  mb-1">
                        <div class="d-flex flex-row flex-wrap">
                            <div v-for="(file, index) in submissionsAndRevisions.files" :key="index" class="submissions w-25 m-1 position-relative">
                              <embed :src="file" class="img-fluid" width="100%" height="100%" />
                              <feather-icon @click="openLink(file)" class="download-icon" role="button" icon="DownloadIcon" size="20"/>
                            </div>
                        </div>
                    </div>
                </b-card-body>

                <b-card-body v-if="submissionsAndRevisions.type =='Revision' " class="d-flex flex-column justify-content-between p-1 ">
                    <b-button class="Revision" block v-b-toggle="'accordion-'+1">
                    <div class="d-flex flex-row w-40  justify-content-between">
                                <div>
                                    {{ submissionsAndRevisions.type }}

                                </div>
                                <div class="d-flex flex-content justify-content-between w-25">
                                    <div>
                                        {{ submissionsAndRevisions.date }}
                                    </div>
                                    <div>
                                        {{ submissionsAndRevisions.time }}
                                    </div>
                                </div>
                            </div>
                            </b-button>
                    <div class="d-flex flex-column justify-content-between w-100 h-100 p-1  mb-1">
                        <div>
                            <b-card-text class="titles mb-1"> {{ submissionsAndRevisions.title }} </b-card-text>
                        </div>
                        <div>
                            <b-card-text> {{ submissionsAndRevisions.description }} </b-card-text>
                        </div>
                    </div>

                    <div class="d-flex flex-column justify-content-between w-100 h-100 p-1  mb-1">
                        <div class="d-flex flex-row flex-wrap">
                            <div v-for="(file, index) in submissionsAndRevisions.files" :key="index" class="submissions w-25 m-1 position-relative">
                              <embed :src="file" class="img-fluid" width="100%" height="100%" />
                              <feather-icon @click="openLink(file)" class="download-icon" role="button" icon="DownloadIcon" size="20"/>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </div>
            </b-card>
        </div>

    </div>
    <!-- </b-card>
    </div> -->
</template>


<script>

import {
    BCard, BCardHeader, BCardBody, BCardText, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem, BInputGroup, fontAwesomeIcon,
    BDropdownForm, BFormDatepicker, BFormGroup, BTabs, BTab, BCollapse, VBToggle
} from 'bootstrap-vue';

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BLink,
        BAvatar,
        BBadge,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        fontAwesomeIcon,
        BDropdownForm,
        BFormDatepicker,
        BFormGroup,
        BTabs,
        BTab,
        BCardHeader,
        BCardBody,
        BCollapse,
        BCardText,
    }, directives: {
        'b-toggle': VBToggle
    },
    props: ['projectId'],
    data() {
      return {
        submissions: []
      }
    },

    created() {
        this.fetchSubmissions()
    },
    methods: {
      async fetchSubmissions() {
          const response = await this.$http.get(`Projects/${this.projectId}/SubmissionsLog`);

          let submissions = response.data

          submissions = submissions.map((submission) => {
            return {
        ...submission,
        submissionsAndRevisions: submission.submissionsAndRevisions.map((item) => {
            // Map over each item in submissionAndRevision and update its properties
            return {
                ...item,
                date: new Date(item.dateTime).toLocaleDateString(), // Format item date
                time: new Date(item.dateTime).toLocaleTimeString() // Format item time
            };
        })
    };
          });

          this.submissions = submissions;
      },

      openLink(link) {
        window.open(link);
      }
    }
}
</script>

<style lang="scss" scoped>
.header-button {
    background-color: #F8F8F8 !important;
    // #F8F8F8
}
.Revision
{
    background-color : #F3F2F7!important;
    color:#44434C!important;
}

.header{color: #44434C; font-weight: 700;}
.titles {
    font-weight: 600;
    font-size: 18px;
    // line-height: 27px;
}
.w-25 {
    width: 14% !important;
}
.submissions {
  transition: filter ease 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
}

.download-icon {
  position: absolute;
  top: 10px;
  left: 20px;
}
</style>
<template>
  <b-card no-body class="mb-0 border-top">
    <div class="d-flex flex-column position-relative mt-1">
      <div class="d-flex flex-row h-100">

        <div class="d-flex flex-column w-25 pr-2 border-right">
          <div>
            <!-- <span class="h1"> Designers </span> -->
            <p class="conversations-title mt-1"> Designers </p>
          </div>
          <div>
            <div class="pe-4 mb-3 mt-1">
              <div class="position-relative">
                <input type="text" placeholder="Search" class="form-control search-input" @input="searchConversations">
              </div>
            </div>
          </div>

          <div>
            <div class="d-flex flex-row cursor-pointer mb-2" v-for="conv in conversations" @click="() => openChat(conv)">
              <div class="conv-profile-pic-container">
                <div class="conv-profile-pic">
                  <img :src="conv.messages.professionalImageUrl" class="img-fluid rounded-circle" alt="professional-image">
                </div>
              </div>
              <div class="d-flex conv-prof-name-container">
                <div>
                  <p class="professional-name"> {{ conv.messages && conv.messages.professionalName }} </p>
                </div>
              </div>
              <span v-if="conv.messages.isSelected" class="d-flex align-items-center">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="text-success"
                  size="16"
                />
              </span>
            </div>
          </div>

        </div>

        <div class="d-flex flex-column w-75">
          <div class="d-flex flex-row pl-1 pb-1 border-bottom">
            <span v-if="!activeConv">No Conversation to show</span>
            <div v-else class="d-flex flex-row w-50">
              <div class="align-items-center">
                <div class="conv-profile-pic">
                  <img :src="designerImage" class="img-fluid rounded-circle" alt="professional-image">
                </div>
              </div>
              <div class="d-flex conv-prof-name-container">
                <div>
                  <p class="professional-name"> {{ activeConversation.messages && activeConversation.messages.professionalName }} </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeConv" class="d-flex flex-column p-1">
            <template v-for="msg in activeConversation.messages.chatMessages">
              <div class="d-flex flex-row mb-1" :class="{
                'justify-content-start': msg.sender.type === 'Client',
                'justify-content-end': msg.sender.type === 'Professional',
              }">
                <div class="d-flex flex-column" :class="{'align-items-start': msg.sender.type === 'Client', 'align-items-end': msg.sender.type === 'Professional',}">
                  <div class="font-weight-bolder" style="width: fit-content; margin-bottom: 5px">
                    <span>{{ msg.sender.text }}</span>
                  </div>
                  <div class="p-1 rounded" style="width: fit-content; margin-bottom: 3px" :class="{
                    'bg-grey': msg.sender.type === 'Client',
                    'bg-blue': msg.sender.type === 'Professional',
                  }" v-html="msg.text"></div>
                  <span>{{ formatTime(msg.createdOn) }}</span>
                </div>
              </div>
            </template>
          </div>
        </div>

      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem, BInputGroup, fontAwesomeIcon,
  BDropdownForm, BFormDatepicker, BFormGroup, BTabs, BTab
} from 'bootstrap-vue';
import moment from 'moment';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BAvatar,
    BBadge,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    fontAwesomeIcon,
    BDropdownForm,
    BFormDatepicker,
    BFormGroup,
    BTabs,
    BTab,
  },
  props: ['projectId'],
  data() {
    return {
      messages: [],
      conversations: [],
      activeConversation: {},
      chats: []

    };
  },

  mounted() {
    this.fetchProjectConversations()

  },

  computed: {
    // ...mapState('projects', [
    //   'projectData',
    //   'activeConversation'
    // ]),

    // ...mapGetters('projects', [
    //   'canRequestNewOffer',
    //   'canSendMessages'
    // ]),

    activeConv() {
      return this.activeConversation?.messages;
    },

    designerImage() {
      return this.activeConversation.messages?.professionalImageUrl;
    },

    // showRequestOfferBtn() {
    //   return (!this.messages.request?.id) && this.canRequestNewOffer;
    // },

    // showConnectedFlag() {
    //   return this.projectData.projectStatus.value === 'DesignerSelected'
    //     && this.projectData.professional.value === this.activeConversation.professional.value;
    // },

    // showCompletedFlag() {
    //   return this.projectData.projectStatus.value === 'Completed'
    //     && this.projectData.professional.value === this.activeConversation.professional.value;
    // }
  },

  watch: {},

  methods: {
    async fetchProjectConversations() {
      const response = await this.$http.get(`projects/${this.projectId}/Conversations`);

      console.log('%c response ', 'color: yellow;');
      console.log(response);

      this.chats = response.data;
      this.conversations = response.data;
      this.activeConversation = response?.data[0];
    },
    openChat(msg) {
      this.activeConversation = msg
    },
    searchConversations(e) {
      const value = e.target.value
      this.conversations = this.chats
      if (value && this.conversations) {
        this.conversations = this.conversations.filter((msg) => msg.messages.professionalName.search(value) !== -1)
      }
    },

    formatTime(date) {
      return moment.utc(date).format('YYYY-MM-DD, h:mm a');
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-grey {
  background-color: #EAE8FF;
  color: black;
}

.bg-blue {
  background-color: #2f2f77;
  color: white;
}

.conversations-title {
  font-weight: 500;
  font-size: 24px;
}

.professional-name {
  font-weight: 500;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
}

.conv-profile-pic-container {
  flex-basis: 20%;
  align-items: center;
}

.conv-profile-pic {
  width: 50px;
  height: 50px;
  background-color: #191832;
  border-radius: 50%;
  margin-right: 15px;
}

.conv-prof-name-container {
  flex-basis: 75%;
  align-items: center;
}
</style>
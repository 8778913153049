<template>
    <div class="accordion" role="tablist">
        <template v-for="historyStatus in Object.keys(history)">
            <b-card no-body class="mb-1 p-0 border-left border-right border-bottom ">

                <b-card-header header-tag="header" class="card-header p-0 mb-1" role="tab">

                    <b-button block v-b-toggle="'accordion-'+historyStatus" :variant="`${isDark ? 'dark' : 'light'}`">
                        <div class="d-flex flex-row justify-content-end">
                            <div class="d-flex flex-row w-100 rounded justify-content-between">
                                <div class="d-flex flex-row justify-content-start w-75">
                                    <span 
                                        :class="[getStatusClass(historyStatus)]" class="rounded" 
                                        style="padding-right: 10px; padding-left: 10px; padding-top: 5px; padding-bottom: 5px;"
                                    >
                                        {{ historyStatus }}
                                    </span>
                                </div>
                                <div class="d-flex flex-content justify-content-between w-25">
                                    <div class="d-flex flex-content justify-content-end w-50 align-items-center">
                                        Date
                                    </div>
                                    <div class="d-flex flex-content justify-content-end w-50 align-items-center">
                                        Time
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-button>

                </b-card-header>

                    <b-card-body class="d-flex flex-column justify-content-between p-1">
                        <template v-for="(historyItem, index) in history[historyStatus]" >

                            <div :key="index" class="d-flex flex-row justify-content-between">
                                <div class="d-flex flex-row w-100 rounded justify-content-between">
                                    <div class="d-flex flex-row justify-content-start w-75">
                                        <b-card-text class="titles mb-1" v-html="historyItem.messageText"></b-card-text>
                                    </div>
                                    <div class="d-flex flex-content justify-content-between w-25">
                                        <div class="d-flex flex-content justify-content-end w-50">
                                            <b-card-text class="titles mb-1"> {{ historyItem.date }} </b-card-text>
                                        </div>
                                        <div class="d-flex flex-content justify-content-end w-50">
                                            <b-card-text class="titles mb-1"> {{ historyItem.time }} </b-card-text>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </template>
                    </b-card-body>
            </b-card>
        </template>
    </div>
</template>


<script>

import {
    BCard, BCardHeader, BCardBody, BCardText, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem, BInputGroup, fontAwesomeIcon,
    BDropdownForm, BFormDatepicker, BFormGroup, BTabs, BTab, BCollapse, VBToggle
} from 'bootstrap-vue';
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BLink,
        BAvatar,
        BBadge,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        fontAwesomeIcon,
        BDropdownForm,
        BFormDatepicker,
        BFormGroup,
        BTabs,
        BTab,
        BCardHeader,
        BCardBody,
        BCollapse,
        BCardText,
    },
    directives: {
        'b-toggle': VBToggle
    },
    props: ['projectId'],
    data() {
        return {
            history: [],
            colors: {
                draft: 'gray',
                searchingForDesigners: '#FFE4D9',
                designerSelected: '#FFEFC6',
                completed: '#D7F9E0',
                notAvailable: '#f77c7c'
            }
        }
    },
    setup() {
        const { skin } = useAppConfig()

        const isDark = computed(() => skin.value === 'dark')

        return { skin, isDark }
    },
    created() {
        this.fetchProjectHistory()
    },
    methods: {
        async fetchProjectHistory() {
            const response = await this.$http.get(`Projects/${this.projectId}/history`);

            console.log('RESPONSE HISTORY: ', response.data);

            let history = response.data

            history = history.map((historyItem) => {
                return {
                    ...historyItem,
                    date: new Date(historyItem.createdOn).toLocaleDateString(),
                    time: new Date(historyItem.createdOn).toLocaleTimeString()
                }
            });

            const groupedHistoryItems = {}

            history.forEach(historyItem => {
                
                if (!groupedHistoryItems[historyItem.status]) {
                    groupedHistoryItems[historyItem.status] = []
                }

                groupedHistoryItems[historyItem.status].push(historyItem)

            });

            console.log('HISTORY: ', groupedHistoryItems);


            this.history = groupedHistoryItems;
        },
        
        getStatusClass(statusValue) {
            const classes = {
                Pending: 'draft',
                SearchingForDesigners: 'searching-for-designers',
                PriceSubmitted: 'price-submitted',
                Completed: 'completed',
                default: 'draft'
            }

            const status = statusValue;

            return classes[status] || classes.default;
        }
    }
}
</script>

<style lang="scss" scoped>
.card-header-button {
    background-color: #F8F8F8 !important;
}

.draft {
background-color: grey;
color: white;
}

.searching-for-designers {
background-color: #FFE4D9;
color: #707070;
}

.price-submitted {
background-color: #FFEFC6;
color: black !important;
}

.completed {
background-color: #D7F9E0;
color: #707070;
}

.not-available {
background-color: #f77c7c;
color: #FFE4D9;
}
</style>
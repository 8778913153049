<template>
    <!-- <div>
        <b-card no-body class="mb-0"> -->
    <div class="accordion" role="tablist">

        <div v-for="(submission, index) in submissions" :key="index">
            <b-card no-body class="mb-1 p-0 border-left border-right border-bottom ">
                <b-card-header header-tag="header" class="p-0 mb-1" role="tab">
                    <b-button block v-b-toggle="'accordion-'+submission.id">
                        <div class="d-flex flex-row justify-content-end">
                            <div class="d-flex flex-row w-50 rounded block justify-content-between">
                                <div>
                                    {{ submission.type }}
                                </div>
                                <div class="d-flex flex-content justify-content-between w-25">
                                    <div>
                                        {{ submission.date }}
                                    </div>
                                    <div>
                                        {{ submission.time }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-button>
                </b-card-header>

                <b-card-body class="d-flex flex-column justify-content-between p-1">
                    <div class="d-flex flex-column justify-content-between w-100 h-100 p-1 border mb-1">
                        <div>
                            <b-card-text class="titles mb-1"> Message </b-card-text>
                        </div>
                        <div>
                            <b-card-text> {{ submission.comments }} </b-card-text>
                        </div>
                    </div>

                    <div class="d-flex flex-column justify-content-between w-100 h-100 p-1 border mb-1">
                        <div>
                            <b-card-text class="titles mb-1"> Attachments </b-card-text>
                        </div>

                        <div class="d-flex flex-row flex-wrap">
                            <div v-for="(file, index) in submission.files" :key="index" class="submissions w-25 m-1 position-relative">
                              <embed :src="file" class="img-fluid" width="100%" height="100%" />
                              <feather-icon @click="openLink(file)" class="download-icon" role="button" icon="DownloadIcon" size="19"/>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-card>
        </div>

    </div>
    <!-- </b-card>
    </div> -->
</template>


<script>

import {
    BCard, BCardHeader, BCardBody, BCardText, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem, BInputGroup, fontAwesomeIcon,
    BDropdownForm, BFormDatepicker, BFormGroup, BTabs, BTab, BCollapse, VBToggle
} from 'bootstrap-vue';

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BLink,
        BAvatar,
        BBadge,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        fontAwesomeIcon,
        BDropdownForm,
        BFormDatepicker,
        BFormGroup,
        BTabs,
        BTab,
        BCardHeader,
        BCardBody,
        BCollapse,
        BCardText,
    }, directives: {
        'b-toggle': VBToggle
    },
    props: ['projectId'],
    data() {
      return {
        submissions: []
      }
    },

    created() {
        this.fetchSubmissions()
    },
    methods: {
      async fetchSubmissions() {
          const response = await this.$http.get(`Projects/${this.projectId}/SubmissionsLog`);

          let submissions = response.data

          submissions = submissions.map((submission) => {
              return {
                  ...submission,
                  date: new Date(submission.dateTime).toLocaleDateString(),
                  time: new Date(submission.dateTime).toLocaleTimeString()
              }
          });

          this.submissions = submissions;
      },

      openLink(link) {
        window.open(link);
      }
    }
}
</script>

<style lang="scss" scoped>
.header-button {
    background-color: #F8F8F8 !important;
    // #F8F8F8
}

.titles {
    font-weight: 600;
    font-size: 18px;
    // line-height: 27px;
}

.submissions {
  transition: filter ease 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
}

.download-icon {
  position: absolute;
  top: 10px;
  left: 20px;
}
</style>
<template>
    <div>
        <b-card no-body class="mb-0 p-1 align-item-center">

            <div class="d-flex flex-row w-100 h-100 justify-content-around mb-1">
                <b-form-textarea v-model="newComment" placeholder="Write a comment..." rows="3" />
            </div>
            <div class="d-flex flex-row w-100 h-100 justify-content-start mb-3">
                <b-button variant="primary" @click="addComment">
                    <span class="text-nowrap">Add Comment</span>
                </b-button>
            </div>

            <template v-for="comment in comments">
                <div key="comment.id" class="d-flex flex-column w-100 border-bottom mb-2 p-1">
                    <div class="d-flex flex-row justify-content-start align-items-center mb-1">
                        <div class="profile-pic">

                        </div>
                        <div class="align-items-center">
                            {{ comment.ownerName }}
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-content-start align-items-center p-1">
                        {{ comment.comment }}
                    </div>
                </div>
            </template>
        </b-card>
    </div>
</template>


<script>

import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem, BInputGroup, fontAwesomeIcon,
    BDropdownForm, BFormDatepicker, BFormGroup, BTabs, BTab, BFormTextarea
} from 'bootstrap-vue';

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BLink,
        BAvatar,
        BBadge,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        fontAwesomeIcon,
        BDropdownForm,
        BFormDatepicker,
        BFormGroup,
        BTabs,
        BTab,
        BFormTextarea
    },
    props: ['projectId'],
    data() {
        return {
            newComment: null,
            comments: null
        }
    },
    created() {
        this.fetchProjectComments()
    },
    methods: {
        async fetchProjectComments() {
            const response = await this.$http.get(`Projects/${this.projectId}/comments`);

            console.log('RESPONSE: ', response.data);

            this.comments = response.data
            // [{
            //     id: 0,
            //     OwnerName: 'Hamzeh Oweis',
            //     comment: 'Hello there !',
            //     createdOn: new Date('2023-08-04 22:52:10.834 +0300').toLocaleDateString()
            // }]
        },
        async addComment() {
            await this.$http.post(`Projects/Comments?projectId=${this.projectId}&Comment=${this.newComment}`)
            await this.fetchProjectComments()
            this.newComment = null
        }
    }
}
</script>

<style lang="scss" scoped>
.profile-pic {
    width: 50px;
    height: 50px;
    background-color: #191832;
    border-radius: 50%;
    margin-right: 15px;
}
</style>